import { cloneElement, PropsWithChildren, ReactElement } from "react";

import { Flex, getValidChildren, Grid, useBreakpointValue } from "@nestoca/ui";
import clsx from "clsx";
import { BsChevronRight } from "react-icons/bs";

import styles from "./breadcrumbs.module.scss";

type BreadcrumbItemProps = {
  hasArrowBefore?: boolean;
  isCurrentPage?: boolean;
  hasEntitySelector?: boolean;
};

const BreadcrumbsItem = ({
  hasArrowBefore = true,
  children,
  isCurrentPage = false,
  hasEntitySelector = false,
}: PropsWithChildren<BreadcrumbItemProps>) => {
  return (
    <Flex
      gap={5}
      align="center"
      className={clsx(styles["breadcrumbs__item"], {
        [styles["entity-selector"]]: hasEntitySelector,
      })}
      aria-current={isCurrentPage ? "page" : undefined}
    >
      {hasArrowBefore && (
        <BsChevronRight
          className={clsx(styles["breadcrumbs__chevron"], {
            [styles["entity-selector__chevron"]]: hasEntitySelector,
          })}
          aria-hidden="true"
          size={15}
        />
      )}
      {children}
    </Flex>
  );
};
BreadcrumbsItem.id = "BreadcrumbItem";

const BreadcrumbsWrapper = ({ children }: PropsWithChildren) => {
  const validChildren = getValidChildren(children);
  const isMobile = useBreakpointValue({ default: true, md: false });

  const isChildBreadcrumbItem = (
    child: React.ReactNode
  ): child is ReactElement<BreadcrumbItemProps> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return child?.type.id === "BreadcrumbItem";
  };

  const clones = validChildren
    .map((child) => {
      if (isChildBreadcrumbItem(child)) {
        return cloneElement(child, {
          ...child.props,
        });
      }

      return null;
    })
    .filter((n) => n);

  return (
    <nav
      className={styles.breadcrumbs}
      aria-label="breadcrumbs"
      data-testid="breadcrumbs"
    >
      {isMobile ? (
        <Grid gap={3} className={styles["breadcrumbs__item-container-mobile"]}>
          {clones}
        </Grid>
      ) : (
        <Flex
          wrap="wrap"
          gap={5}
          className={styles["breadcrumbs__item-container"]}
        >
          {clones}
        </Flex>
      )}
    </nav>
  );
};

export const Breadcrumbs = Object.assign(BreadcrumbsWrapper, {
  Item: BreadcrumbsItem,
});
