import { useDatadogContext } from "./use-datadog-context";

import type { TenantClient } from "@nestoca/multi-tenant";

export const useDatadogTenant = (tenant: TenantClient | null) => {
  // The global context data exceeds the recommended 3KiB threshold.
  // More details: https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#customer-data-exceeds-the-recommended-3kib-warning
  const data = tenant
    ? {
        tenantId: tenant.id,
        tenantSlug: tenant.slug,
        auth0: {
          clientId: tenant.auth0.clientId,
        },
      }
    : null;

  useDatadogContext("tenant", data);
};
