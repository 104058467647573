import { Role } from "@nestoca/gate-core";
import { useTenantSetting } from "@nestoca/multi-tenant";
import {
  ApplicationStateGroup,
  CURRENT_STATUS_FILTER,
  PRODUCT_TYPE_FILTERS,
  DIGITAL_STATUSES,
} from "@shared/constants";
import { useTranslation } from "react-i18next";

export const useGetTransactionTypeFilterOptions = (role?: Role) => {
  const { t } = useTranslation("dashboard");
  const { settings: transactionTypes } = useTenantSetting(
    "tenantTransactionTypes"
  );

  return transactionTypes.map((applicationType) => {
    const type =
      role && role === "externalbroker" && applicationType === "RENEWAL"
        ? "TRANSFER"
        : applicationType;

    return {
      value: applicationType,
      label: t(`table.applicationTypes.${type}`),
    };
  });
};

// Default filters state for each column where filtering is enabled
export const useGetFiltersInitialState = (
  applicationStateGroup: ApplicationStateGroup,
  role: Role
): { id: string; value: string[] }[] => {
  const { settings: transactionTypes } = useTenantSetting(
    "tenantTransactionTypes"
  );

  return [
    {
      id: "productType",
      value: [...PRODUCT_TYPE_FILTERS],
    },
    {
      id: "isDigital",
      value: [...DIGITAL_STATUSES],
    },
    {
      id: "transactionType",
      value: transactionTypes,
    },
    {
      id: "currentStatus",
      value: [...CURRENT_STATUS_FILTER(role)[applicationStateGroup]],
    },
  ];
};
